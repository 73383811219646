
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType, UtProbeItem } from "prometheus-synced-ui";
import { LITE_FEATURE, UtProbeDetails } from "@/domain";

import { IUtProbeService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class UtProbesTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get utProbeService() {
    return this.container.resolve<IUtProbeService>(S.UT_PROBE_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.probes, (p) => p.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/UtProbeCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/UtProbeUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Diamètre (mm)",
        align: "center",
        sortable: true,
        value: "diameter",
      },
      {
        text: "Fréquence (MHz)",
        align: "center",
        sortable: true,
        value: "frequency",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  probes: UtProbeItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.probes = await this.utProbeService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(probe: UtProbeDetails) {
    this.probes.push(probe.toItem());
  }

  update(probe: UtProbeDetails) {
    const index = _.findIndex(this.probes, (p) => p.id == probe.id);
    if (index != -1) {
      this.probes.splice(index, 1, probe.toItem());
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const probe = _.find(this.probes, (p) => p.id == id)!;
      const result = await this.utProbeService.update(id, {
        serialNumber: probe.serialNumber!,
        equipmentType: probe.equipmentType!,
        equipmentBrandId: probe.equipmentBrand.id!,
        frequency: probe.frequency!,
        diameter: probe.diameter!,
        pvNumber: probe.pvNumber,
        disabled: !probe.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
